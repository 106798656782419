<template>
  <div class="navbar-container d-flex content align-items-center justify-content-center">
    <!-- Left Col -->
    <div>
      <logo />
    </div>

    <div class="mx-auto">
      <app-navbar-horizontal-layout-middle :menus="navbarMenuItems" />
    </div>
    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto pr-2">
      <dashboard v-if="isUserLogin" />
      <register
        v-if="!isUserLogin"
        class="hide-mobile"
      />
      <login v-if="!isUserLogin" />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import Logo from '@/components/Logo.vue'
import AppNavbarHorizontalLayoutMiddle from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutMiddle.vue'
import navbarMenuItems from '@/navigation/landing'
import {
  isUserLoggedIn,
} from '@/auth/utils'
import Register from './components/Register.vue'
import Login from './components/Login.vue'
import Dashboard from './components/Dashboard.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Register,
    Login,
    Logo,
    Dashboard,
    AppNavbarHorizontalLayoutMiddle,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isUserLogin: isUserLoggedIn(),
      navbarMenuItems,
    }
  },
}
</script>

<style lang="scss">
.hide-mobile {
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
