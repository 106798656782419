<template>
  <div class="logo">
    <b-link class="navbar-brand" to="/">
      <span class="brand-logo">
        <b-img src="@/assets/tekov/tekov-logo.svg" alt="logo" />
      </span>
    </b-link>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
export default {
  components: {
    BLink,
    BImg
  }
}
</script>

<style></style>
