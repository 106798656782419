export default [
  {
    name: 'Home',
    route: 'pages.landing-page',
    type: 'link',
  },
  /* {
    name: 'Hasil Karya',
    route: 'pages.portfolio',
    type: 'link',
  }, */
  {
    name: 'Promo dan Diskon',
    route: 'pages.promo',
    type: 'link',
  },
  /* {
    name: 'Literasi',
    route: 'pages.articles.index',
    type: 'link',
  }, */
  {
    name: 'Tentang',
    route: 'pages.about',
    type: 'link',
  },
]
