<template>
  <div class="d-none d-md-block justify-content-center mx-auto align-items-center">
    <ul class="nav navbar-nav">
      <nav-menu v-for="(menu, index) in menus" :key="index" :menu="menu" />
    </ul>
  </div>
</template>

<script>
import { BNav, BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

import NavMenu from './components/NavMenu.vue'

export default {
  components: {
    BLink,
    BImg,
    BNav,

    NavMenu,
  },
  props: {
    menus: {
      type: Array,
      default: () => [
        {
          name: 'Menu 1',
          submenus: [
            {
              name: 'sub 1',
              url: 'link',
            },
            {
              name: 'sub 2',
              url: 'link',
            },
          ],
          isActive: true,
        },
        {
          name: 'Menu 2',
          submenus: [
            {
              name: 'sub 2',
              url: 'link',
            },
          ],
        },
      ],
    },
  },
}
</script>

<style>
.navbar-header {
  left: 0 !important;
}
</style>
