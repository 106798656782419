<template>
  <div>
    <b-nav-item @click="$router.push({name: checkAuthorizeRole('dashboard')})">
      <b-button variant="primary">
        <i class="ti ti-home" />
        <span class="d-none d-md-block">Dashboard</span>
      </b-button>
    </b-nav-item>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem, BButton } from 'bootstrap-vue'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BNavItem,
    BButton,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark, checkAuthorizeRole }
  },
}
</script>
