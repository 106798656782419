<template>
  <div>
    <b-nav-item-dropdown
      v-if="menu.submenus"
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
    >
      <template #button-content>
        <span
          class="ml-50"
          :class="menu.isActive ? 'text-primary font-weight-bold' : 'text-primary'"
        >
          {{ menu.name }}
        </span>
      </template>
      <b-dropdown-item
        v-for="(submenu, subIndex) in menu.submenus"
        :key="subIndex"
        @click="$i18n.locale = submenu.locale"
      >
        <b-link>
          <span class="ml-50">{{ submenu.name }}</span>
        </b-link>
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-link
      v-else
      :to="{ name: menu.route }"
      :disabled="$route.name === menu.route"
      :class="$route.name === menu.route ? 'text-primary-dark font-weight-bold' : 'text-primary-light font-weight-bold'"
      class="ml-50 px-1 nav-font-weight"
    >
      {{ menu.name }}
    </b-link>
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BLink } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BLink,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({
        name: '',
        submenus: [
          {
            name: 'sub 1',
            url: 'link',
          },
        ],
        isActive: false,
      }),
    },
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    isMenuActive() {
      return this.menu.route === this.$router.currentRoute.name
    },
  },
}
</script>

<style>
.text-primary-dark {
  color: #011b4b !important;
}
.text-primary-light {
  color: #0c3e97 !important;
}
.nav-font-weight {
  font-size: 18px;
}
</style>
