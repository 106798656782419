<template>
  <div>
    <b-nav-item @click="$router.push({name: 'auth-login'})">
      <b-button variant="primary">
        Masuk
        <i class="ti ti-arrow-narrow-right" />
      </b-button>
    </b-nav-item>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem, BButton } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
    BButton,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
