<template>
  <footer class="bg-primary text-white py-4">
    <!-- Company informations -->
    <b-row>
      <b-col sm="12" lg="4">
        <b-img fluid src="@/assets/tekov/teknoreka-logo.svg" />

        <p class="py-2 m-0">
          Perusahaan IT anak bangsa yang telah berpengalaman menyelesaikan dan
          membangun insfrastruktur IT dari berbagai instansi baik swasta maupun
          negerI di Indonesia.
        </p>

        <div class="d-flex">
          <b-link class="mr-2" href="#" target="_blank">
            <i class="ti ti-brand-instagram icon-medium" />
          </b-link>
          <b-link class="mr-2" href="#" target="_blank">
            <i class="ti ti-brand-twitter icon-medium" />
          </b-link>
        </div>
      </b-col>
      <b-col></b-col>
      <b-col sm="12" lg="7">
        <b-row>
          <b-col sm="12" lg="4" class="mt-sm-4">
            <h4 class="font-weight-bold text-white">Company</h4>
            <b-link class="d-block text-white mt-1" href="#">
              Tentang Kami
            </b-link>
            <b-link class="d-block text-white mt-1" href="#">
              Profil Perusahaan
            </b-link>
            <b-link class="d-block text-white mt-1" href="#">
              Blog
            </b-link>
          </b-col>
          <b-col sm="12" lg="4" class="mt-sm-4">
            <h4 class="font-weight-bold text-white">Solutions</h4>
            <b-link class="d-block text-white mt-1" href="#">
              Lowongan Pekerjaan
            </b-link>
            <b-link class="d-block text-white mt-1" href="#">
              Pendaftaran Magang
            </b-link>
          </b-col>
          <b-col sm="12" lg="4" class="mt-sm-4">
            <h4 class="font-weight-bold text-white">Contact Info</h4>

            <!-- Location -->
            <div class="d-flex mt-1">
              <i class="ti ti-map-pin icon-medium mr-1" />
              <p class="m-0">
                Perumahan Candi Gebang Blok 1 No. 18, Jetis, Wedomartani,
                Ngemplak, Sleman Regency, Special Region of Yogyakarta 55584
              </p>
            </div>

            <!-- Email -->
            <div class="d-flex mt-1">
              <i class="ti ti-mail icon-medium mr-1" />
              <p class="m-0">
                corporate@teknoreka.com teknoreka.tinara@gmail.com
              </p>
            </div>

            <!-- Phone -->
            <div class="d-flex mt-1">
              <i class="ti ti-phone icon-medium mr-1" />
              <p class="m-0">
                +62 82112882656
              </p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr />

    <!-- Copyright -->
    <b-row>
      <b-col>
        <span class="d-flex justify-content-center">
          Copyright © {{ new Date().getFullYear() }}
          <b-link
            class="ml-25 text-white"
            href="https://teknoreka.com/"
            target="_blank"
          >
            PT. Teknoreka Inovasi Nusantara
          </b-link>
          <span class="d-none d-sm-inline-block">, All rights Reserved</span>
        </span>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
import { BLink, BRow, BCol, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BImg,
  },
}
</script>

<style lang="scss">
footer {
  padding: 1rem 4rem !important;
}

.icon-medium {
  font-size: 1.5rem;
  color: white;
}

@media (max-width: 768px) {
  footer {
    padding: 1rem 4rem !important;
  }

  .mt-sm-4 {
    margin-top: 2rem !important;
  }
}
</style>
